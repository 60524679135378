import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="arrowslideformation"
export default class extends Controller {
  connect() {}
}

//----------------- Slider de XP -----------------//

const Xps = document.querySelectorAll(".container-slide-xp");
const nbXP = Xps.length;

const next = document.querySelector(".right-arrow-xp");
const previous = document.querySelector(".left-arrow-xp");

const dots = document.querySelectorAll(".slider-dot");
const nbDots = dots.length;

let counter = 0;

function xpSuivant() {
  Xps.forEach((xp) => xp.classList.remove("active"));
  Xps.forEach((xp) => xp.classList.remove("active-left"));
  dots.forEach((dot) => dot.classList.remove("active"));

  if (counter < nbXP - 1) {
    counter++;
    // } else {
    //   counter = 0;
  }

  Xps[counter].classList.add("active");
  dots[counter].classList.add("active");

  // console.log(counter);
}

if (next) {
  next.addEventListener(
    "click",
    function () {
      xpSuivant();
    },
    false
  );
}

function xpPrecedent() {
  Xps.forEach((xp) => xp.classList.remove("active"));
  Xps.forEach((xp) => xp.classList.remove("active-left"));
  dots.forEach((dot) => dot.classList.remove("active"));

  if (counter > 0) {
    counter--;
    // } else {
    //   counter = nbXP - 1;
  }

  Xps[counter].classList.add("active-left");
  dots[counter].classList.add("active");
}

if (previous) {
  previous.addEventListener(
    "click",
    function () {
      xpPrecedent();
    },
    false
  );
}

// function handleGesture() {
//   const swipeDistance = touchendX - touchstartX;

//   if (swipeDistance < -minSwipeDistance) {
//     xpSuivant();
//   }

//   if (swipeDistance > minSwipeDistance) {
//     xpPrecedent();
//   }
// }

if (dots) {
  dots.forEach((dot, i) => {
    dot.addEventListener("click", () => {
      Xps.forEach((Xp) => Xp.classList.remove("active"));
      dots.forEach((dot) => dot.classList.remove("active"));

      counter = i;

      Xps[counter].classList.add("active");
      dots[counter].classList.add("active");
    });
  });
}

let resizeTimer;
window.addEventListener("resize", () => {
  document.body.classList.add("resize-animation-stopper");
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(() => {
    document.body.classList.remove("resize-animation-stopper");
  }, 400);
});

// document.addEventListener("touchstart", (event) => {
//   touchstartX = event.changedTouches[0].screenX;
// });

// document.addEventListener("touchmove", (event) => {
//   event.preventDefault();
// });

// document.addEventListener("touchend", (event) => {
//   touchendX = event.changedTouches[0].screenX;
//   handleGesture();
// });

// slide for Formation -----------------------------------

// const currentRoute = window.location.pathname;

// if (currentRoute === "/projets") {
//   const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);

//   const items = document.querySelectorAll(".slider-slide");
//   const nbSlide = items.length;

//   let count = 0;
//   let intervalId;

//   if (!isMobile) {
//     // check si mobile ou pas
//     function slideSuivante() {
//       items[count].classList.remove("active");

//       if (count < nbSlide - 1) {
//         count++;
//       } else {
//         count = 0;
//       }

//       items[count].classList.add("active");
//     }

//     // Code pour le défilement automatique à intervalle régulier
//     function autoSlide() {
//       intervalId = setInterval(slideSuivante, 4000);
//     }

//     autoSlide();
//   }
// }

// keypress to go to next slide or previous slide -----------------------------------

// function keyPress(e) {
//   console.log(e);
//   if (e.keyCode === 37) {
//     slidePrecedente();
//   } else if (e.keyCode === 39) {
//     slideSuivante();
//   }
// }

// document.addEventListener("keydown", keyPress);

//----------------- touchmove de XP -----------------//
// let initialPosition = null;
// let moving = false;
// let transform = 0;

// const gestureStart = (e) => {
//   initialPosition = e.pageX;
//   moving = true;
//   const transformMatrix = window
//     .getComputedStyle(Xps[counter])
//     .getPropertyValue("transform");
//   if (transformMatrix !== "none") {
//     transform = parseInt(transformMatrix.split(",")[4].trim());
//   }
// };

// const gestureMove = (e) => {
//   if (moving) {
//     const currentPosition = e.pageX;
//     const diff = currentPosition - initialPosition;
//     Xps[counter].style.transform = `translateX(${transform + diff}px)`;
//   }
// };

// const gestureEnd = (e) => {
//   moving = false;
// };

// if (window.PointerEvent) {
//   window.addEventListener("pointerdown", gestureStart);
//   window.addEventListener("pointermove", gestureMove);
//   window.addEventListener("pointerup", gestureEnd);
// } else {
//   window.addEventListener("touchdown", gestureStart);
//   window.addEventListener("touchmove", gestureMove);
//   window.addEventListener("touchup", gestureEnd);
// }
